import { IUser } from "./user";

export interface IControlChatEnableTranslateBody {
  control: string;
  user: Pick<IUser, 'id' | 'fullName'>;
  room: number;
  uid: string;
  target: ChatLangs;
}

export enum ChatLangs {
  RUSSIAN = 'ru',
  ENGLISH = 'en',
  GERMAN = 'de',
  ITALIAN = 'it',
  CHINESE = 'zh-CN'
}

export enum FlacDecoderLocales {
  RUSSIAN = 'ru-RU',
  ENGLISH = 'en-US',
  GERMAN = 'de-DE',
  ITALIAN = 'it-IT',
  CHINESE = 'zh-CN'
}
